import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
`

export const Wrapper = styled.div`
  max-width: 1200px;
  display: flex;
  position: relative;

  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 40px;
  padding-bottom: 30px;

  @media (max-width: 1170px) {
    justify-content: center;
  }
`

export const Footer = styled.div`
  padding-bottom: 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 30px;
  margin-bottom: -20px;
  color: #2e5589;
  font-size: 14px;
  font-weight: 600;
`
