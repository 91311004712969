import styled from "styled-components"
import { device } from "../../../constants/breakpoints"

export const Container = styled.div`
  margin: 30px;
  border-radius: 12px;
  background: white;
  float: left;
  box-shadow: 0px 0px 10px #0260d91a;

  width: 330px;
  :hover {
    box-shadow: 0px 0px 50px #0260d91a;
  }
`

export const Image = styled.div`
  cursor: pointer;
  background: url(${props => props.img}) center center/cover no-repeat;
  background-position: 0px -20px;
  height: 260px;
  width: 100%;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;

  @media ${device.tablet} {
    background-position: top center;
  }
`

export const Inner = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 15px;
`

export const Title = styled.p`
  cursor: pointer;
  color: #001229;
  font-size: 22px;
  font-weight: 600;

  :hover {
    color: ${({ theme }) => theme.blue};
  }
`

export const SubTitle = styled.p`
  color: #264a79;
  font-weight: 500;
  line-height: 20px;
  margin-top: -15px;
  height: 30px;
  font-size: 14px;
`

export const IconRow = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: row;

  &[title~="location"] {
    margin-top: 15px;
  }
`

export const Icon = styled.img`
  margin-right: 10px;
  height: 22px;
  cursor: pointer;
  width: auto;
  background-size: contain;
  &[title~="euro"] {
    height: 25px;
    margin-top: -2px;
  }
`

export const LocationText = styled.p`
  font-size: 13px;
  margin-top: -5px;

  @media ${device.tablet} {
    margin-top: 0px;
    font-size: 10px;
  }
`

export const VideoText = styled.p`
  font-weight: 600;
  font-size: 15px;
  margin-top: 2px;
  color: #1e75e8;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Button = styled.button`
  border-radius: 10px;
  width: 140px;
  padding: 12px;
  border-width: 0;
  color: white;
  font-size: 14px;
  font-weight: 600;
`
