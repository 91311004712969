export const Doctors = [
  {
    key: "1",
    nome: "Dr.ssa Lucia Maria",
    specializzazione: "Chirurgo specializzato in Chirurgia plastica",
    rating: 3,
    hasCam: true,
    hasVideo: true,
    hasEuro: true,
    list_provincie: "Milano - Roma",
    hasVideCon: false,
    url_foto: require("../images/card.png"),
  },
  {
    key: "2",

    nome: "Dr.ssa Lucia Maria",
    specializzazione:
      "Chirurgo specializzato in Chirurgia plastica e ricostruttiva",
    rating: 3,
    hasCam: true,
    hasVideo: true,
    hasEuro: true,
    list_provincie: "Milano - Roma",
    hasVideCon: false,
    url_foto: require("../images/card.png"),
  },
  {
    key: "3",

    nome: "Dr.ssa Lucia Maria",
    specializzazione: "Chirurgo specializzato in Chirurgia plastica",
    rating: 3,
    hasCam: true,
    hasVideo: true,
    hasEuro: true,
    list_provincie: "Milano - Roma",
    hasVideCon: true,
    url_foto: require("../images/card.png"),
  },
  {
    key: "4",

    nome: "Dr.ssa Lucia Maria",
    specializzazione: "Chirurgo specializzato in Chirurgia plastica",
    rating: 3,
    hasCam: true,
    hasVideo: true,
    hasEuro: true,
    list_provincie: "Milano - Roma",
    hasVideCon: true,
    url_foto: require("../images/card.png"),
  },
  {
    key: "4",

    nome: "Dr.ssa Lucia Maria",
    specializzazione: "Chirurgo specializzato in Chirurgia plastica",
    rating: 3,
    hasCam: true,
    hasVideo: true,
    hasEuro: true,
    list_provincie: "Milano - Roma",
    hasVideCon: true,
    url_foto: require("../images/card.png"),
  },
]
