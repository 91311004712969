import React from "react"
import Form from "../PreForm/PreForm"
import {
  Inner,
  Image,
  Container,
  SubTitle,
  Title,
  VideoText,
  LocationText,
  IconRow,
  Icon,
  Button,
  Buttons,
} from "./styles/Crd"

const Card = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

Card.Image = function CardImage({ children, ...restProps }) {
  return <Image {...restProps}>{children}</Image>
}

Card.Inner = function CardInner({ children, ...restProps }) {
  return <Inner {...restProps}>{children}</Inner>
}

Card.Title = function CardTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>
}

Card.SubTitle = function CardSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>
}

Card.IconRow = function CardIconRow({ children, ...restProps }) {
  return <IconRow {...restProps}>{children}</IconRow>
}

Card.Icon = function CardIcon({ children, ...restProps }) {
  return <Icon {...restProps}>{children}</Icon>
}

Card.LocationText = function CardLocationText({ children, ...restProps }) {
  return <LocationText {...restProps}>{children}</LocationText>
}

Card.VideoText = function CardVideoText({ children, ...restProps }) {
  return <VideoText {...restProps}>{children}</VideoText>
}

Card.Buttons = function CardButtons({ children, ...restProps }) {
  return <Buttons {...restProps}>{children}</Buttons>
}
Card.Button = function CardButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>
}
export default Card
