import React, { useRef, useState } from "react"
import Cards from "../../components/Cards/Cards"
import Card from "../../components/Card/Card"
import { StarsWithPeople } from "../../components/Stars/Stars"
import { Doctors } from "../../constants/doctors"
import { navigate } from "gatsby"
import GlobalIcon from "../../components/Atoms/GlobalIcon"
import styled from "styled-components"
import ContactModal from "../../components/ContactFormModal/ContactFormModal"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import ContactModalMobile from "../../components/ContactModalMobile/ContactModalMobile"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../redux/layoutSlice"
import navigateToChirurgo from "../../functions/navigateToChirurgo"
import { device } from "../../constants/breakpoints"
import { trimLocationText } from "../../functions/trimLocationText"

export const CardSingle = ({ doctor, ...restProps }) => {
  const dispatch = useDispatch()
  return (
    <Card {...restProps}>
      <Card.Image
        onClick={() => navigateToChirurgo(doctor)}
        img={doctor.url_foto || require("../../images/card.png")}
      />
      <Card.Inner>
        <Card.Title onClick={() => navigateToChirurgo(doctor)}>
          {doctor.nome}
        </Card.Title>
        <Card.SubTitle>{doctor.specializzazione}</Card.SubTitle>
        <StarsWithPeople
          style={{ marginTop: 15 }}
          count={doctor.rating}
          people={doctor.reviews_count}
          fontSize={12}
        />
        <Card.IconRow>
          {doctor.count_foto > 0 && (
            <Card.Icon
              style={{ marginRight: 20 }}
              onClick={() => navigateToChirurgo(doctor, "#prepost")}
              src={require("../../images/Camera.png")}
            />
          )}
          {doctor.count_video > 0 && (
            <Card.Icon
              style={{ marginRight: 20 }}
              onClick={() => navigateToChirurgo(doctor, "#video")}
              src={require("../../images/video.png")}
            />
          )}
          {doctor.finanziamenti > 0 && (
            <Card.Icon
              onClick={() => navigateToChirurgo(doctor, "#promo")}
              title={"euro"}
              src={require("../../images/euro.png")}
            />
          )}
        </Card.IconRow>
        <Card.IconRow title={"location"}>
          <Card.Icon
            height="25px"
            width="auto"
            src={require("../../images/pin.png")}
          />
          <Card.LocationText>
            {trimLocationText(doctor.list_provincie)}
          </Card.LocationText>
        </Card.IconRow>
        {false && (
          <Card.IconRow style={{ marginTop: -5 }}>
            <Card.Icon
              style={{ marginTop: 6.5, height: 14 }}
              width="auto"
              src={require("../../images/videocon.png")}
            />
            <Card.VideoText>Videoconsulto online disponibile</Card.VideoText>
          </Card.IconRow>
        )}
        <Card.Buttons>
          <Card.Button
            onClick={() => navigateToChirurgo(doctor)}
            style={{ backgroundColor: "#264A79" }}
          >
            Scheda
          </Card.Button>
          <Card.Button
            onClick={() => dispatch(openModal(doctor))}
            style={{ backgroundColor: "#337AB7 " }}
          >
            Contatta
          </Card.Button>
        </Card.Buttons>
      </Card.Inner>
    </Card>
  )
}

const NoResultText = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin-top: 20px;
  color: ${props => props.theme.blue};
`

const Container = styled.div`
  min-height: 400px;
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  @media ${device.laptop} {
    padding-top: 100px;
  }
`
export default function CardsContainer({
  title,
  notitle,
  doctors = Doctors,
  parentRef,
  text,
  onClick,
}) {
  if (!doctors.length) {
    return (
      <Container>
        <GlobalIcon
          style={{ height: 100 }}
          img={require("../../images/not-found.png")}
        />
        <NoResultText>
          Sembra che nessun risultato si adegua ai tuoi filtri...
        </NoResultText>
        <Cards.Footer onClick={onClick}>
          {text || "Tutti gli altri chirurghi estetici"}
        </Cards.Footer>
      </Container>
    )
  }
  return (
    <Cards>
      <Cards.Wrapper>
        {!notitle && (
          <>
            <Cards.SubTitle>
              {title || "INCONTRA IL TUO DOTTORE"}
            </Cards.SubTitle>
            <Cards.Title>I Dottori più richiesti</Cards.Title>
          </>
        )}
        {doctors.map((doctor, index) => (
          <CardSingle
            parentRef={parentRef}
            data-aos="fade-zoom-in"
            data-aos-delay={100}
            doctor={doctor}
          />
        ))}

        <Cards.Footer onClick={() => navigate("/chirurghi-estetici")}>
          {text || "Tutti gli altri chirurghi estetici"}
        </Cards.Footer>
      </Cards.Wrapper>
    </Cards>
  )
}
