import React from "react"
import Form from "../PreForm/PreForm"
import { Wrapper, Container, Footer } from "./styles/Crds"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { SectionSubTitle, SectionTitle } from "../../constants/global-styles"

const Cards = ({ children, ...restProps }) => {
  return <Container {...restProps}>{children}</Container>
}

Cards.Wrapper = function Cardsrapper({ children, ...restProps }) {
  return <Wrapper {...restProps}>{children}</Wrapper>
}

Cards.Title = function CardsSectionTitle({ children, ...restProps }) {
  return <SectionTitle {...restProps}>{children}</SectionTitle>
}

Cards.SubTitle = function CardsSectionSubTitle({ children, ...restProps }) {
  return <SectionSubTitle {...restProps}>{children}</SectionSubTitle>
}

Cards.Footer = function CardsFooter({ children, ...restProps }) {
  return (
    <Footer {...restProps}>
      {children}
      <FontAwesomeIcon
        style={{ fontSize: 14, marginLeft: 5, fontWeight: 100 }}
        icon={faArrowRight}
      />
    </Footer>
  )
}

export default Cards
